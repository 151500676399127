<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">BOT</h4>
            <span>봇 관련 항목을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <div class="form-inline">
                <input type="text" class="form-control mr-2" v-model="filter"/>
                <select class="form-control mr-2" v-model="sorted">
                  <option value="none" selected>정렬 없음</option>
                  <option value="asc">오름차순</option>
                  <option value="desc">내림차순</option>
                </select>
                <button class="btn btn-info" @click="resetFilter()">리셋</button>
              </div>
              <div class="ml-auto">
                <button :class="{'disabled': isLoading}" class="btn btn-primary mr-2" @click="goRegistBot()">봇 등록</button>
                <button :class="{'disabled': isLoading}" class="btn btn-success mr-1" @click="runAllData(true)">모두 실행</button>
                <button :class="{'disabled': isLoading}" class="btn btn-danger mr-2" @click="runAllData(false)">모두 중지</button>
                <button :class="{'disabled': isLoading}" class="btn btn-success mr-1" @click="runCheckData(true)">선택 실행</button>
                <button :class="{'disabled': isLoading}" class="btn btn-danger" @click="runCheckData(false)">선택 중지</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">BOT 설정</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>
                        <button class="badge btn btn-primary" type="button" @click="allChecked()">전체</button><br/>
                        <button class="badge btn btn-success" type="button" @click="runChecked()">실행중</button><br/>
                        <button class="badge btn btn-danger" type="button" @click="stopChecked()">중지중</button><br/>
                        <button class="badge btn btn-outline-primary" type="button" @click="resetChecked()">리셋</button>
                      </th>
                      <th>봇 번호</th>
                      <th>봇 닉네임</th>
                      <th>마켓</th>
                      <th>모니터링 시간(초)</th>
                      <th>거래 계정</th>
                      <th>봇 타입</th>
                      <th>1회 수량</th>
                      <th>거래 구분</th>
                      <th>거래 시세</th>
                      <th>마크업(%)</th>
                      <th>상단 범위(%)</th>
                      <th>하단 범위(%)</th>
                      <th>상태</th>
                      <th>활성화</th>
                      <th>액션</th>
                    </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in filteredItems" :key="index">
                      <td>
                        <input class="form-check-inline" type="checkbox" :value="item.idx" v-model="checkedItems">
                      </td>
                      <td>{{ item.idx }}</td>
                      <td>{{ item.botName }}</td>
                      <td>{{ item.marketPair }}</td>
                      <td>{{ item.roundInterval }}</td>
                      <td>{{ item.botUserIdx }}</td>
                      <td>{{ item.botType === 'ORDER' ? '호가봇' : '체결봇' }}</td>
                      <td>{{ item.roundAmount }}</td>
                      <td v-if="item.botType === 'ORDER'">{{ item.isBuy ? '매수' : '매도' }}</td>
                      <td v-else>{{ `-` }}</td>
                      <td v-if="item.exchanges === 'NONE'">{{ item.priceFixed }}</td>
                      <td v-else>{{ item.exchanges }}</td>
                      <td>{{ $big(item.markUp).mul(100) }}</td>
                      <td>{{ $big(item.markUpper).mul(100) }}</td>
                      <td>{{ $big(item.markBottom).mul(100) }}</td>
                      <td>{{ item.state === true ? '실행' : '중지' }}</td>
                      <td class="text-center">
                        <div class="btn-group">
                          <button class="badge btn btn-success mr-1" :disabled="item.state === true" :class="{ disabled: item.state === true || isLoading }" @click="runData(item.idx, true)">실행</button>
                          <button class="badge btn btn-danger" :disabled="item.state === false" :class="{ disabled: item.state === false || isLoading  }" @click="runData(item.idx, false)">중지</button>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="btn-group">
                          <button :class="{'disabled': isLoading}" class="badge btn btn-primary mr-1" @click="goEditBot(item.idx)">수정</button>
                          <button :class="{'disabled': isLoading}" class="badge btn btn-outline-danger" @click="deleteData(item.idx)">삭제</button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="15" style="text-align: center;">관련된 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../common/Pagination";

export default {
  name: 'BotList',
  components: {
    Pagination,
  },
  data() {
    return {
      isLoading: false,
      size: '999',
      page: 0,
      total: 0,

      filter: '',
      sorted: 'none',

      //data
      items: [
        // {
        //   idx: 0,
        //   botName: '',
        //   marketPair: '',
        //   roundInterval: 0,
        //   botUserIdx: '',
        //   botType: '', // ORDER : 호가봇, TRADE : 체결봇
        //   isBuy: false, // 호가봇일 경우 거래방향, 널 허용
        //   roundAmount: 0,
        //   exchanges : '', // 등록시세 (BINACE, UBIT, NONE)
        //   priceFixed: 0, // 고정시세, 널 허용
        //   markUp: 0, // 기준시세의 마크업, 널 허용
        //   markUpper: 0,
        //   markBottom: 0,
        //   state: false, // 실행중 여부
        // },
      ],
      checkedItems: [],
    }
  },
  created() {
    this.sorted = sessionStorage.getItem('bot_sorted') || 'none';
    this.filter = sessionStorage.getItem('bot_filter') || '';

    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'bot', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    this.getList(page)
  },
  methods: {
    resetFilter() {
      this.filter = ''
      this.sorted = 'none'
    },
    getList(page) {
      let formData = new FormData()
      if(typeof page === 'undefined') page = 1
      formData.append('page', page)
      formData.append('limit', this.size)

      this.$post(this.$BOT_LIST, this.$options.name + '_getList', formData,(result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {}, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }
        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    runData(_idx, _action) {
      if(this.isLoading) {
        return
      }

      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 3000)

      let formDate = new FormData()
      formDate.append('idxList', _idx)
      formDate.append('isRun', _action)
      this.$post(this.$BOT_RUN, this.$options.name + '_setData', formDate, () => {
        this.getList(this.page)
      }, (result) => {
        this.httpError(result)
      })
    },
    runCheckData(_action) {
      if(this.checkedItems.length === 0) {
        this.notify('error', '목록이 비어 있습니다.', false)
        return
      }

      if(this.isLoading) {
        return
      }

      if(!confirm('정말 이 일괄작업을 실행하시겠습니까?')) {
        return
      }

      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 5000)

      let formDate = new FormData()
      for (let i = 0; i < this.checkedItems.length; i++) {
        formDate.append('idxList', this.checkedItems[i])
      }
      formDate.append('isRun', _action)
      this.$post(this.$BOT_RUN, this.$options.name + '_setCheck', formDate, () => {
        this.getList(this.page)
      }, (result) => {
        this.httpError(result)
      })
    },
    allChecked() {
      this.checkedItems = this.filteredItems.map((item) => item.idx)
    },
    runChecked() {
      this.checkedItems = this.filteredItems.filter((item) => item.state === true).map((item) => item.idx)
    },
    stopChecked() {
      this.checkedItems = this.filteredItems.filter((item) => item.state === false).map((item) => item.idx)
    },
    resetChecked() {
      this.checkedItems = []
    },
    runAllData(_action) {
      if(this.filteredItems.length === 0) {
        this.notify('error', '목록이 비어 있습니다.', false)
        return
      }

      if(this.isLoading) {
        return
      }

      if(!confirm('정말 이 일괄작업을 실행하시겠습니까?')) {
        return
      }

      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 5000)

      let formDate = new FormData()
      for (let i = 0; i < this.filteredItems.length; i++) {
        formDate.append('idxList', this.filteredItems[i].idx)
      }
      formDate.append('isRun', _action)
      this.$post(this.$BOT_RUN, this.$options.name + '_setActive', formDate, () => {
        this.getList(this.page)
      }, (result) => {
        this.httpError(result)
      })
    },
    goRegistBot() {
      if(this.isLoading) {
        return
      }

      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 5000)

      this.$router.push({
        name: 'BotRegist'
      })
    },
    goEditBot(_idx) {
      if(this.isLoading) {
        return
      }

      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 5000)

      this.$router.push({
        name: 'BotEdit',
        params: { idx: _idx }
      })
    },
    deleteData(_idx) {
      if(this.isLoading) {
        return
      }

      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 5000)

      let formData = new FormData()
      formData.append('idx', _idx)

      this.$post(this.$BOT_DELETE.replace('?', _idx), this.$options.name + '_deleteData', formData,() => {
        this.notify('success', '봇을 삭제했습니다.', false)
        this.getList(this.page)
      }, (result) => {
        this.httpError(result)
      })
    },
  },
  computed: {
    filteredItems() {
      let items = [...this.items]

      if(this.sorted === 'asc') {
        items.sort((a, b) => {
          return a.botName > b.botName ? 1 : -1
        })
      } else if(this.sorted === 'desc') {
        items.sort((a, b) => {
          return a.botName < b.botName ? 1 : -1
        })
      }

      return items.filter((item) => {
        return item.botName.toLowerCase().includes(this.filter.toLowerCase())
      })
    }
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
    'filter'() {
      sessionStorage.setItem('bot_filter', this.filter)
    },
    'sorted'() {
      sessionStorage.setItem('bot_sorted', this.sorted)
    }
  },
}
</script>

<style>
</style>
